<template>
    <div class="wrapper">
      <topbar2></topbar2>
      <header2></header2>
      <div class="content-wrapper">
        <div class="content-header">
          <div class="row">    
                   <div class="col-md-7 col-12">
                     <div class="card-header">
                    <h3 class="card-title"> Company List </h3>
                  </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <div class="srcpadmobie"> 
                    <input class="form-control topmsrc5" type="text" v-model="searchQuery" placeholder="Search" />
                    </div>
                  </div>
                  <div class="col-md-2 col-12">
    <div class="col-md-3 col-3 txtright">
    <router-link to="/Addcompany" @click.prevent="toggleMenu">
    <div style="width: 150px; padding-left: 10px; padding-right: 10px;" class="btn btn-primary btn-md topmsrc5 topm5"> <i class="fa fa-plus nav-icon"></i> Add Company </div>
    </router-link>
    </div>
                  </div>
                </div>
         
        </div>
        <section class="content">
          <div class="container-fluid">
    
            <div class="row">
              <section class="col-lg-12 connectedSortable">
                <div class="">
                  <div class="">
                    <div class="tab-content p-0">
                      <div class="chart tab-pane active">                  
                         
                             <div class="row">
    <div class="col-md-12" v-for="(user, index) in filteredResources" :key="user.id">
    <div class="card card-primary cardpad">              
                  <!-- /.card-header -->
    <div class="card-body">
    <div v-on:click="show(user.id)">
    <div class="row">
    <div class="col-lg-3 col-12">{{ user.name }}</div>  
    <div class="col-lg-3 col-12"><span class="htitle">Name:</span> {{ user.name }}</div>
    <div class="col-lg-3 col-6"><span class="htitle">Owner Name:</span> {{ user.owner_name }}</div>
    <div class="col-lg-3 col-6"><span class="htitle"><i class="fa fa-envelope"></i></span> {{ user.company_email }}</div>
    
    </div>
    <div class="row">
    <div class="col-lg-3 col-6"><span class="htitle">State:</span> {{ user.state }}</div>
    <div class="col-lg-3 col-6"><span class="htitle">City:</span> {{ user.city }}</div>
    <div class="col-lg-6 col-12"><span class="htitle">Address:</span> {{ user.address }}</div>
    </div>
    </div>
    <div v-if="this.action.includes('15') && this.action.includes('16')">
    <div class="row" v-if="this.showbtn == user.id">
    <div class="col-lg-12 col-xs-12">
    <hr>
    <button @click="showclose()" class="btn btn-primary btn-xs"> <i class="fa fa-times-circle"></i> Close </button>&nbsp;
    <button v-if="this.action.includes('15')" @click="removeData(user.id, index)" class="btn btn-danger btn-xs"> <i class="fa fa-times-circle"></i> Remove </button>&nbsp;
    <router-link  v-if="this.action.includes('16')" class="btn btn-success btn-xs" :to="{path:'/updatecustomer',query:{client_id: user.id,mobile_no: user.mobile_no,client_name: user.name}}"> <i class="fas fa-edit"></i> Edit </router-link></div>
    </div>
    </div>
                   
                  </div>
                  <!-- /.card-body -->
                </div>
    
            </div>
          </div>    
    
    
                        <div class="hello">
                          <VueTailwindPagination
          :current="currentPage"
          :total="total"
          :per-page="perPage"
          @page-changed="onPageClick($event)"
        />
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <footer2></footer2>
    </div>
    </template>
    
    <script>
    import { defineComponent } from 'vue'
    import { createToast } from 'mosha-vue-toastify'
    import 'mosha-vue-toastify/dist/style.css'
    
    import "@ocrv/vue-tailwind-pagination/dist/style.css";
    import VueTailwindPagination from "@ocrv/vue-tailwind-pagination"
    import Header from './../Header.vue'
    import Footer from './../Footer.vue'
    import Topbar from './../Topbar.vue'
    import axios from "axios";
    export default {
      extends:defineComponent,
       components:{     
         VueTailwindPagination,
            "header2": Header,
            "footer2": Footer,
            "topbar2": Topbar
          },
        data() {          
          return {
            resourcesaction:[],         
            company_id:this.$store.state.auth.user.data[0].company_id,
            role_id:this.$store.state.auth.user.data[0].role_id,
            user_id: this.$store.state.auth.user.data[0].id,
            id: this.$route.params.id,
            token: localStorage.token,   
            myData: null, 
            showbtn:this.id,    
            resources: [],
            searchQuery:'',
            currentPage: '1',
            perPage: '10',
            total: this.total,
            display: true,
            }
        },   
       computed: {
        filteredResources (){
          if(this.searchQuery){
          return this.resources.filter((user)=>{       
            return user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || user.owner_name.toLowerCase().includes(this.searchQuery.toLowerCase())
          })
          }else{
            return this.resources;
          }
        }
      },  
    
      methods: { 
        async getAction() {
              var response = await axios.post(
                `http://laundry.ezeehost.com:4909/ClientIspmateApi/getroleeditscreen`, {
                  company_id: this.company_id, 
                  user_id: this.user_id,
                  role_id: this.role_id         
            })      
                  this.resourcesaction = response.data;               
                  this.action = response.data.data[0].action_ids;   
                  this.role_name = response.data.data[0].role_name;             
            },
        showsuccessmsg(msgData){
           createToast(msgData,{
              type: 'success',position: 'top-right',showIcon: true,timeout: 3000
            })
         },
         showfailedmsg(msgData){
           createToast(msgData,{
              type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
            })
         },   
        
          async getData() {
          var response = await axios.post(
            `http://laundry.ezeehost.com:4909/ClientIspmateApi/companylist`, {
              id: '1', 
              name: 'Testing Company',
              owner_name: '', 
              company_email: '', 
              state: '', 
              city: '', 
              search_data: '',                  
            });      
          this.resources = response.data.data;
          this.total = response.data.total_record;
          console.log(this.total)
           
        },
        onPageClick(event) {
          this.currentPage = event;
          this.getData(this.currentPage);
        },
        async removeData(id, index){   
          if(confirm("Do you really want to delete?")){   
          axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/deleteclient', {
                  user_id: this.user_id,
                  client_id: id,
                  company_id: this.company_id
                })
                    .then(response => {                  
                      this.resources.splice(index,1); 
                      this.showsuccessmsg(response.data.response);   
                      this.$router.push({path:'/clientlist'})                 
                      console.log(response.data.response)               
                    })
                    .catch(error => {        
                        console.log(error)
                        ///this.showfailedmsg('Something went wrong');    
                })
    
            }  
        }, 
        show(id){     
          this.showbtn = id;
        },
        showclose(){          
          this.showbtn = false;
        }  
        },
    
      mounted() {  
        setTimeout(() => {
          this.myData = 'Example Data';
        }, 2000);        
          this.getData();
          this.getAction();
      },
    
      
    
      };
    </script>
    
    <style>
    .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0 1.25rem 1.25rem 1.25rem !important;
    }
      @import '../../assets/dist/css/custom.css';        
    </style>